
import { defineComponent, computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/presentation/store/enums/StoreEnums";

import { COMPONENT_NAME, LOCAL_STORAGE, ROUTE_PAGES } from "@/domain/Constant";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";


export default defineComponent({
  name: COMPONENT_NAME.KT_USERS_MENU,
  components: {},
  props: {
    userPhotoUrl: String,
    email: String,
    name: String,
    surName: String,
  },
  setup() {
    const { t } = useI18n();
    const swalNotification = new SwalNotification();
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem(LOCAL_STORAGE.LANG)
      ? (localStorage.getItem(LOCAL_STORAGE.LANG) as string)
      : "tr";

    const countries = {
      tr: {
        flag: "/media/flags/turkey.svg",
        name: "Türkçe",
      },
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "/media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "/media/flags/germany.svg",
        name: "German",
      },
      fr: {
        flag: "/media/flags/france.svg",
        name: "French",
      },
    };

    const signOut = () => {
      if (environment.env == "prod") {
      const analytics = getAnalytics();
      logEvent(analytics, "user_logout");
      }

      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: ROUTE_PAGES.SIGN_IN }));
    };

    const setLang = (lang) => {
      localStorage.setItem(LOCAL_STORAGE.LANG, lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});
