<template>
  <!--begin::Menu-->
  <div
    class="
      menu
      menu-sub
      menu-sub-dropdown
      menu-column
      menu-rounded
      menu-gray-600
      menu-state-bg-light-primary
      fw-bold
      py-4
      fs-6
      w-275px
    "
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5">
          <img alt="Logo" :src="userPhotoUrl" />
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ name }} {{ surName }}
            <span
              class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
              >Aktif</span
            >
          </div>
          <a href="#" class="fw-bold text-muted text-hover-primary fs-8">{{
            email
          }}</a>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--
    <div class="menu-item px-5">
      <router-link to="/changeTenant" class="menu-link px-5">
        {{ $t("common.menu.changeTenant") }}
      </router-link>
    </div>

    <div class="menu-item px-5">
      <router-link to="/pages/profile/overview" class="menu-link px-5">
        {{ $t("common.menu.myProfile") }}
      </router-link>
    </div>

    <div class="menu-item px-5">
      <router-link to="/pages/profile/overview" class="menu-link px-5">
        <span class="menu-text">My Projects</span>
        <span class="menu-badge">
          <span class="badge badge-light-danger badge-circle fw-bolder fs-7"
            >3</span
          >
        </span>
      </router-link>
    </div>

    <div
      class="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="center, top"
    >
      <router-link to="/pages/profile/overview" class="menu-link px-5">
        <span class="menu-title">My Subscription</span>
        <span class="menu-arrow"></span>
      </router-link>

      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <div class="menu-item px-3">
          <router-link to="/pages/profile/overview" class="menu-link px-5">
            Payments
          </router-link>
        </div>

        <div class="menu-item px-3">
          <router-link
            to="/pages/profile/overview"
            class="menu-link d-flex flex-stack px-5"
          >
            Statements

            <i
              class="fas fa-exclamation-circle ms-2 fs-7"
              data-bs-toggle="tooltip"
              title="View your statements"
            ></i>
          </router-link>
        </div>

        <div class="separator my-2"></div>

        <div class="menu-item px-3">
          <div class="menu-content px-3">
            <label
              class="form-check form-switch form-check-custom form-check-solid"
            >
              <input
                class="form-check-input w-30px h-20px"
                type="checkbox"
                value="1"
                checked
                name="notifications"
              />
              <span class="form-check-label text-muted fs-7">
                Notifications
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="separator my-2"></div>
-->
    <div
      class="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="center, top"
    >
      <router-link to="" class="menu-link px-5">
        <span class="menu-title position-relative">
          {{ $t("common.menu.language") }}
          <span
            class="
              fs-8
              rounded
              bg-light
              px-3
              py-2
              position-absolute
              translate-middle-y
              top-50
              end-0
            "
          >
            {{ currentLangugeLocale.name }}
            <img
              class="w-15px h-15px rounded-1 ms-2"
              :src="currentLangugeLocale.flag"
            />
          </span>
        </span>
      </router-link>

      <!--begin::Menu sub-->
      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('tr')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage('tr') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                src="/media/flags/turkey.svg"
                alt="Turkey"
              />
            </span>
            Türkçe
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('en')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage('en') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                src="/media/flags/united-states.svg"
                alt="England"
              />
            </span>
            English
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('es')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage('es') }"
          >
            <span class="symbol symbol-20px me-4">
              <img class="rounded-1" src="/media/flags/spain.svg" alt="Spain" />
            </span>
            Spanish
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('de')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage('de') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                src="/media/flags/germany.svg"
                alt="Germany"
              />
            </span>
            German
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('fr')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage('fr') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                src="/media/flags/france.svg"
                alt="France"
              />
            </span>
            French
          </a>
        </div>
        <!--end::Menu item-->
      </div>
      <!--end::Menu sub-->
    </div>
    <!--end::Menu item-->

    <!--
    <div class="menu-item px-5 my-1">
      <router-link to="/pages/profile/overview" class="menu-link px-5">
        {{ $t("common.menu.accountSettings") }}
      </router-link>
    </div>
  -->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a @click="signOut()" class="menu-link px-5">
        {{ $t("common.menu.signOut") }}
      </a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/presentation/store/enums/StoreEnums";

import { COMPONENT_NAME, LOCAL_STORAGE, ROUTE_PAGES } from "@/domain/Constant";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";


export default defineComponent({
  name: COMPONENT_NAME.KT_USERS_MENU,
  components: {},
  props: {
    userPhotoUrl: String,
    email: String,
    name: String,
    surName: String,
  },
  setup() {
    const { t } = useI18n();
    const swalNotification = new SwalNotification();
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem(LOCAL_STORAGE.LANG)
      ? (localStorage.getItem(LOCAL_STORAGE.LANG) as string)
      : "tr";

    const countries = {
      tr: {
        flag: "/media/flags/turkey.svg",
        name: "Türkçe",
      },
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "/media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "/media/flags/germany.svg",
        name: "German",
      },
      fr: {
        flag: "/media/flags/france.svg",
        name: "French",
      },
    };

    const signOut = () => {
      if (environment.env == "prod") {
      const analytics = getAnalytics();
      logEvent(analytics, "user_logout");
      }

      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: ROUTE_PAGES.SIGN_IN }));
    };

    const setLang = (lang) => {
      localStorage.setItem(LOCAL_STORAGE.LANG, lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});
</script>
