export interface MenuItem {
  pages: {
    sectionTitle?: string;
    heading?: string;
    route: string;
    svgIcon: string;
    fontIcon: string;
    permission?: string;
    sub?: {
      heading: string;
      route: string;
      sectionTitle?: string;
      sub?: {
        heading: string;
        route: string;
        sectionTitle?: string;
      }[];
    }[];
  }[];
  sectionTitle?: string;
  heading?: string;
  route?: string;
  permission?: string;
  sub?: {
    heading: string;
    route: string;
    sectionTitle?: string;
    sub?: {
      heading: string;
      route: string;
      sectionTitle?: string;
    }[];
  }[];
}

const MaldavarMenuConfig: MenuItem[] = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "/media/icons/duotune/general/gen001.svg",
        fontIcon: "bi-app-indicator",
        permission: "DEFAULT",
      },
    ],
  },
  {
    heading: "animal",
    route: "/animal",
    permission: "ANIMAL",
    pages: [
      {
        heading: "barnyards",
        route: "/barnyards",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "allAnimals",
        route: "/animalList",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "createAnimal",
        route: "/createAnimal",
        svgIcon: "/media/icons/duotune/general/gen041.svg",
        fontIcon: "bi-person",
      },
      /*
      {
        heading: "vaccineList",
        route: "/vaccineList",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      */

      /*
      {
        heading: "food",
        route: "/feed",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "treatment",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "overheads",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      */
    ],
  },
  /*
  {
    heading: "sales",
    route: "/sales",
    permission: "ORDER",
    pages: [
      {
        heading: "order",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "shipment",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "bill",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "waybill",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
    ],
  },
  */
  {
    heading: "basicKnowledge",
    route: "/basicKnowledge",
    permission: "BASIC",
    pages: [
      {
        heading: "animalTypes",
        route: "/animalTypes",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "breeds",
        route: "/breeds",
        svgIcon: "/media/icons/duotune/communication/com005.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "animalTags",
        route: "/animalTags",
        svgIcon: "/media/icons/duotune/communication/com008.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "vaccines",
        route: "/vaccines",
        svgIcon: "/media/icons/duotune/medicine/med007.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "foods",
        route: "/foods",
        svgIcon: "/media/icons/duotune/communication/com007.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "diseases",
        route: "/diseases",
        svgIcon: "/media/icons/duotune/medicine/med008.svg",
        fontIcon: "bi-person",
      },
    ],
  },
  /*
  {
    heading: "staff",
    route: "/staff",
    permission: "PURCHASE_ORDER",
    pages: [
      {
        heading: "staffList",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "authorization",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
    ],
  },
    */
  /*
  {
    heading: "buy",
    route: "/buy",
    permission: "PURCHASE_ORDER",
    pages: [
      {
        heading: "buyFeed",
        route: "/buyFeed",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },

      {
        heading: "buyAnimal",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "supplies",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "machine",
        route: "/404",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
   
    ],
  },
     */
];

export default MaldavarMenuConfig;
