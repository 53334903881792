<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
   
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <div class="me-0">
        <a
          href="#"
          class="btn btn-sm btn-light btn-active-light-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          {{
            activeTenantName
          }}

          <span class="svg-icon svg-icon-5 m-0">
            <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
          </span>
        </a>
        <!--begin::Menu-->
        <div
          class="
            menu
            menu-sub
            menu-sub-dropdown
            menu-column
            menu-rounded
            menu-gray-600
            menu-state-bg-light-primary
            fw-bold
            fs-7
            w-125px
            py-4
          "
          data-kt-menu="true"
        >
          <!--begin::Menu item-->
          <div
            class="menu-item px-3"
            v-for="(tenantEstate, index) in tenantEstates"
            :key="index"
          >
            <a class="menu-link px-3" @click="changeTenant(tenantEstate)">
              {{ tenantEstate.tenantEstate.data.attributes.name }}</a
            >
          </div>
          <!--end::Menu item-->
        </div>
      </div>
    </div>
    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
      id="kt_header_user_menu_toggle"
    >
      <!--begin::Menu-->
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <img :src="userPhotoUrl" alt="profile" />
      </div>
      <KTUserMenu
      :userPhotoUrl="userPhotoUrl"
    :email="email"
    :name="name"
    :surName="surName"></KTUserMenu>
      <!--end::Menu-->
    </div>
    <!--end::User -->

    <!--begin::Heaeder menu toggle-->
    <div
      class="d-flex align-items-center d-lg-none ms-2 me-n3"
      title="Show header menu"
    >
      <div
        class="btn btn-icon btn-active-light-primary"
        id="kt_header_menu_mobile_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="/media/icons/duotune/text/txt001.svg" />
        </span>
      </div>
    </div>
    <!--end::Heaeder menu toggle-->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import KTUserMenu from "@/presentation/layout/header/partials/UserMenu.vue";
import {
  COMPONENT_NAME,
} from "@/domain/Constant";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ChangeTenantEstateModel } from "@/domain/authentication/changeTenantEstate/model/ChangeTenantEstateModel";

export default defineComponent({
  name: COMPONENT_NAME.TOPBAR,
  props: {
    activeTenantName: String,
    tenantEstates: Object,
    userPhotoUrl: String,
    email: String,
    name: String,
    surName: String,
  },
  components: {
    KTUserMenu,
  },
  setup() {
    const { t, te } = useI18n();
    const store = useStore();
    const userController = store.state.ControllersModule.userController;

    const changeTenant = (tenantEstate: any) => {
      const changeTenantEstateModel: ChangeTenantEstateModel = {
        tenantEstateId: tenantEstate.tenantEstate.data.id,
      };

      userController
        .changeTenantEstate(changeTenantEstateModel)
        .then((response) => {
          location.reload();
        });
    };

    return {
      changeTenant,
    };
  },
});
</script>
