
import { defineComponent, onMounted } from "vue";
import KTUserMenu from "@/presentation/layout/header/partials/UserMenu.vue";
import {
  COMPONENT_NAME,
} from "@/domain/Constant";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ChangeTenantEstateModel } from "@/domain/authentication/changeTenantEstate/model/ChangeTenantEstateModel";

export default defineComponent({
  name: COMPONENT_NAME.TOPBAR,
  props: {
    activeTenantName: String,
    tenantEstates: Object,
    userPhotoUrl: String,
    email: String,
    name: String,
    surName: String,
  },
  components: {
    KTUserMenu,
  },
  setup() {
    const { t, te } = useI18n();
    const store = useStore();
    const userController = store.state.ControllersModule.userController;

    const changeTenant = (tenantEstate: any) => {
      const changeTenantEstateModel: ChangeTenantEstateModel = {
        tenantEstateId: tenantEstate.tenantEstate.data.id,
      };

      userController
        .changeTenantEstate(changeTenantEstateModel)
        .then((response) => {
          location.reload();
        });
    };

    return {
      changeTenant,
    };
  },
});
