
import { defineComponent } from "vue";
import KTTopbar from "@/presentation/layout/header/Topbar.vue";
import KTMenu from "@/presentation/layout/header/Menu.vue";
import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/presentation/helper/LayoutConfig";
import { COMPONENT_NAME } from "@/domain/Constant";

export default defineComponent({
  name: COMPONENT_NAME.KT_HEADER,
  props: {
    activeTenantName: String,
    tenantEstates: Object,
    title: String,
    userPhotoUrl: String,
    email: String,
    name: String,
    surName: String,
  },
  components: {
    KTTopbar,
    KTMenu,
  },
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
});
