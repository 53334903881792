const DocMenuConfig = [
  /*
    {
      mainPages: [
        {
          heading: "mainDashboard",
          route: "/home",
          svgIcon: "media/icons/duotune/art/art002.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "deneme",
          route: "/deneme",
          svgIcon: "media/icons/duotune/general/gen019.svg",
          fontIcon: "bi-layers",
        },
      ],
    },
    {
      pages: [
        {
          heading: "dashboard",
          route: "/dashboard",
          svgIcon: "media/icons/duotune/art/art002.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
    */
  ];
  
  export default DocMenuConfig;
  