
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/presentation/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/presentation/assets/ts/components";

import { version } from "@/presentation/helper/Documentation";
import { asideMenuIcons } from "@/presentation/helper/LayoutConfig";
import MainMenuConfig from "@/presentation/config/MainMenuConfig";
import MaldavarMenuConfig from "@/presentation/config/MaldavarMenuConfig";
import { COMPONENT_NAME } from "@/domain/Constant";

// maldavari mainmenu ile degistirince menü düzeliyor...
export default defineComponent({
  name: COMPONENT_NAME.KT_ASIDE_MENU,
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      MaldavarMenuConfig,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
