<template>
  <!--begin::Header-->
  <div id="kt_header" style="" class="header align-items-stretch">
    <!--begin::Container-->
    <div
      :class="{
        'container-fluid': headerWidthFluid,
        'container-xxl': !headerWidthFluid,
      }"
      class="d-flex align-items-stretch justify-content-between"
    >
      <!--begin::Aside mobile toggle-->
      <div
        class="d-flex align-items-center d-lg-none ms-n3 me-1"
        title="Show aside menu"
      >
        <div
          class="btn btn-icon btn-active-light-primary"
          id="kt_aside_mobile_toggle"
        >
          <span class="svg-icon svg-icon-2x mt-1">
            <inline-svg src="/media/icons/duotune/abstract/abs015.svg" />
          </span>
        </div>
      </div>
      <!--end::Aside mobile toggle-->
      <!--begin::Mobile logo-->
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <router-link to="/dashboard" class="d-lg-none">
          <img alt="Logo" src="/media/logos/logo.png" class="h-30px" />
        </router-link>
      </div>
      <!--end::Mobile logo-->
      <h1 class="text-dark fw-bolder my-3 fs-3 pt-5 pe-5">
        {{ title }}
      </h1>
      <!--begin::Wrapper-->
      <div
        class="
          d-flex
          align-items-stretch
          justify-content-between
          flex-lg-grow-1
        "
      >
        <!--begin::Navbar-->
        <div class="d-flex align-items-stretch" id="kt_header_menu_nav">
          <KTMenu></KTMenu>
        </div>
        <!--end::Navbar-->

        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <KTTopbar
            :activeTenantName="activeTenantName"
            :tenantEstates="tenantEstates"
            :userPhotoUrl="userPhotoUrl"
            :email="email"
            :name="name"
            :surName="surName"
          ></KTTopbar>
        </div>
        <!--end::Topbar-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import KTTopbar from "@/presentation/layout/header/Topbar.vue";
import KTMenu from "@/presentation/layout/header/Menu.vue";
import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/presentation/helper/LayoutConfig";
import { COMPONENT_NAME } from "@/domain/Constant";

export default defineComponent({
  name: COMPONENT_NAME.KT_HEADER,
  props: {
    activeTenantName: String,
    tenantEstates: Object,
    title: String,
    userPhotoUrl: String,
    email: String,
    name: String,
    surName: String,
  },
  components: {
    KTTopbar,
    KTMenu,
  },
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
});
</script>
