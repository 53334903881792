<template>
  <!--begin::Footer-->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      :class="{
        'container-fluid': footerWidthFluid,
        'container-xxl': !footerWidthFluid,
      }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1">2024&copy;</span>
        <a
          href="https://ogzatech.com/"
          target="_blank"
          class="text-gray-800 text-hover-primary"
          >OgzaTech</a
        >
      </div>
      <!--end::Copyright-->

      <!--begin::Menu-->
      <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <li class="menu-item">
          <a href="https://ogzatech.com/tr/" target="_blank" class="menu-link px-2">{{$t("common.layout.about")}}</a>
        </li>

        <li class="menu-item">
          <a href="https://ogzatech.com/tr/" target="_blank" class="menu-link px-2">{{$t("common.layout.contact")}}</a>
        </li>

        <li class="menu-item">
          <router-link to="privacy"><a class="menu-link px-2">          {{$t("common.layout.privacy")}}       </a></router-link>

        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Footer-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/presentation/helper/LayoutConfig";
import { COMPONENT_NAME } from "@/domain/Constant";

export default defineComponent({
  name: COMPONENT_NAME.KT_FOOTER,
  setup() {
    return {
      footerWidthFluid,
    };
  },
});
</script>
